var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"publication-list"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("Reporting"))),_c('v-chip',{staticClass:"ml-2",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.totalItems)+" ")]),_c('v-spacer'),(_vm.$can('download','Report'))?_c('export-excel',{attrs:{"options":_vm.downloadObject},on:{"refetch-data":function () { return _vm.fetchItems(true, 'reporting'); }}}):_vm._e()],1),_c('v-data-table',{staticClass:"fixed-table",attrs:{"headers":_vm.filters,"items":_vm.items,"options":_vm.options,"page":_vm.page,"server-items-length":_vm.totalItems,"loading":_vm.loading,"loading-text":_vm.$t('Chargement des éléments'),"no-data-text":_vm.$t('Pas de données'),"no-results-text":_vm.$t('Aucun résultat'),"hide-default-footer":"","show-select":false,"dense":"","fixed-header":"","height":_vm.tableHeight},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [_c('header-filters',{attrs:{"headers":_vm.filters}})]}},{key:"item",fn:function(options){return [_c('Row',{attrs:{"options":options,"config":{ url: '/publications/action/', author: 'author', manuscript: 'manuscript', resource: 'Report' }},scopedSlots:_vm._u([{key:"item.author.full_name",fn:function(ref){
var item = ref.item;
return [(item.author && item.author.id)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.author.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.author.avatar ? '' : 'primary',"size":"30"}},[(item.author.avatar)?_c('v-img',{attrs:{"lazy-src":require("@/assets/images/avatars/2.png"),"src":item.author.avatar || require("@/assets/images/avatars/2.png")}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.author.full_name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'author-view', params : { id : item.author_id } }}},[_vm._v(" "+_vm._s(item.author.full_name)+" ")])],1)],1):_vm._e()]}},{key:"item.author.old_author",fn:function(ref){
var item = ref.item;
return [(item.author && item.author.old_author)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e()]}},{key:"item.author.published",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.published)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e()],1)]}},{key:"item.is_text_cover_finished",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.is_text_cover_finished)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e()],1)]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [(item.country)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.country.name))])]):_vm._e()]}},{key:"item.is_reading1",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.is_reading)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e()],1)]}},{key:"item.completion_date",fn:function(ref){
var item = ref.item;
return [(item.completion_date && !item.completion_date.includes('0000'))?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.completion_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' })))]):_vm._e()]}},{key:"item.author.email",fn:function(ref){
var item = ref.item;
return [(item.author && item.author.email)?_c('span',[_vm._v(" "+_vm._s(item.author.email.join(', ')))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status && item.status.id)?_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((item.status.color) + "--text"),attrs:{"small":"","color":item.status.color}},[_vm._v(" "+_vm._s(item.status.name)+" ")]):_vm._e()]}}],null,true)})]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('rows-per-page',{attrs:{"options":_vm.options}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"touchless":"","right":!_vm.$vuetify.rtl,"width":_vm.$vuetify.breakpoint.smAndUp ? 300 : '100%'},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("Confirmer la suppression")))]),_c('v-divider'),_c('v-card-text',[_vm._v(_vm._s(_vm.$t("Confirmez-vous de vouloir supprimer")))]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","outlined":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("Non"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary","loading":_vm.loading},on:{"click":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t("Oui"))+" ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.$vuetify.theme.currentTheme.error,"light":_vm.$vuetify.theme.dark},model:{value:(_vm.isSnackbarBottomVisible),callback:function ($$v) {_vm.isSnackbarBottomVisible=$$v},expression:"isSnackbarBottomVisible"}},[_vm._v(" "+_vm._s(_vm.snackBarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }